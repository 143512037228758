import { Select } from "@chakra-ui/react";

export default function SortDropdown(props: {
  sortOrder: 'default' | 'date' | 'relevance';
  setSortOrder: (sortOrder: 'default' | 'date' | 'relevance') => void;
}) {
  return (
    <div>
      <Select
        value={props.sortOrder}
        onChange={(e) => props.setSortOrder(e.target.value as 'default' | 'date' | 'relevance')}
        style={{ padding: '8px', borderRadius: '4px' }}
        width="200px"
      >
        <option value="default">Best Match</option>
        <option value="date">Sort by date (new-old)</option>
        <option value="relevance">Sort by importance</option>
      </Select>
    </div>
  );
}