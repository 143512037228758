import { CSSProperties, ReactElement, useState } from 'react';
import theme from '../theme';
import {
  Box,
  Button,
  Divider,
  Icon,
  Image,
  Spacer,
  Stack,
  Text,
  Tooltip,
  useBreakpointValue,
} from '@chakra-ui/react';
import {
  Home,
  Search,
  Heart,
  Bookmark,
  Settings,
  LogOut,
  HelpCircle,
  MessageSquare,
} from 'react-feather';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../lib/hooks';
import { useAuth } from 'oidc-react';

const navItemsGroup = [
  { 
    id: 'research',
    label: 'home',
    label_short: 'home',
    path: '/research',
    icon: <Home />
  },
  { 
    id: 'search',
    label: 'search',
    label_short: 'search',
    path: '/search',
    icon: <Search />
  },
  { 
    id: 'recommended_cases',
    label: 'Recommended cases',
    label_short: 'Recommended',
    path: '/recommended cases',
    icon: <Heart />,
    disabled: true,
  },
  { 
    id: 'saved_cases',
    label: 'Saved cases',
    label_short: 'Saved',
    path: '/saved_cases',
    icon: <Bookmark />
  },
];

interface navItemButton {
  text: string;
  textShort: string;
  active: boolean;
  disabled: boolean | undefined;
  isOpen: boolean;
  icon: ReactElement;
  textSize: string;
  textColor: string;
  tabIndex: number;
  onClick: () => void;
}

function NavItemButton({ ...props }: navItemButton) {
  
  const text = useBreakpointValue({ base: props.textShort, lg: props.text });
  
  return (
    <Tooltip
      label={props.disabled ? 'Coming soon' : props.text}
      openDelay={props.disabled ? 0 : 1000}
      placement="end"
    >
      <Button
        variant="ghost"
        width="full"
        maxHeight="8"
        minHeight="8"
        padding={{
          xs: '5',
          lg: '7',
        }}
        cursor="pointer"
        bg={props.active ? theme.colors.purple[100] : ''}
        onClick={props.disabled ? () => {} : props.onClick}
        _hover={{ bg: props.disabled ? theme.colors.gray[400] : theme.colors.purple[200] }}
        disabled={props.disabled}
        tabIndex={props.tabIndex}
      >
        <Stack
          direction="row"
          width="full"
          alignItems="center"
          justifyContent={props.isOpen ? 'flex-start' : 'center'}
        >
          <Icon
            width={6}
            height={6}
            justifyContent="center"
            alignItems="center"
            color={props.textColor}
          >
            {props.icon}
          </Icon>
          <Text
            fontSize={{
              base: 'small',
              lg: 'medium',
            }}
            transition="hideBelow 1s"
            fontWeight={600}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            textColor={props.textColor}
            display={props.isOpen ? 'block' : 'none'}
          >
            {text}
          </Text>
        </Stack>
      </Button>
    </Tooltip>
  );
  
}


export default function Sidebar({ isOpen, onToggle }: { isOpen: boolean; onToggle: () => void }) {

  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();
  const graphViews = useAppSelector((state) => state.viewReducer.graphViews);
  
  return (
    <Stack
      flexDirection="column"
      minWidth={{
        base: 'min-content',
        md: isOpen ? '30%' : 'min-content',
        xl: isOpen ? '18%' : 'min-content',
      }}
      maxWidth={{
        base: 'min-content',
        md: '30%',
        xl: '18%',
      }}
      height="100%"
      padding={{
        base: '2',
        md: '4',
      }}
      bg={theme.colors.gray[100]}
    >
      <Tooltip
        label="click to expand/minimize"
        bgColor={theme.colors.purple[700]}
        placement="right"
      >
        <Box
          display="flex"
          width="full"
          cursor="pointer"
          padding={{
            base: '0',
            xl: '4',
          }}
          justifyContent="center"
          alignItems="center"
          _hover={{ bg: theme.colors.purple[200], transition: 'background-color 0.2s' }}
          transition="background-color 0.2s"
          borderRadius="md"
          onClick={() => onToggle()}
          tabIndex={1}
        >
          <Image src="/logo512.png" height="16" minWidth="16" />
        </Box>
      </Tooltip>

      <Stack direction="column">
        {navItemsGroup.map((e, i) => {
          return (
            <NavItemButton
              text={e.label}
              textShort={e.label_short}
              active={location.pathname === e.path}
              disabled={e.disabled}
              icon={e.icon}
              isOpen={isOpen}
              textSize="medium"
              textColor="black"
              onClick={() => navigate(e.path)}
              tabIndex={2 + i}
            />
          );
        })}
      </Stack>

      <Divider
        style={{ width: '100%', margin: 0, borderWidth: '2px', opacity: '80%' }}
        color="darkgray"
        width={1}
        orientation="horizontal"
      />

      <Text paddingX={2} pt={2} fontSize="sm" fontWeight="bold" textAlign={isOpen ? 'left' : 'center'}>
        Graphs
      </Text>

      <Stack direction="column" height="20em" overflowY="auto" whiteSpace="nowrap" spacing={2}>
        {Object.values(graphViews).map((view, i) => {
          return (
            <NavItemButton
              text={view.name}
              textShort={view.name}
              active={location.pathname === `/graph/${view.id}`}
              disabled={false}
              icon={<Bookmark />}
              isOpen={isOpen}
              textSize="small"
              textColor="black"
              tabIndex={10 + i}
              onClick={() => navigate(`/graph/${view.id}`)}
            />
          );
        })}
      </Stack>

      <Spacer />

      <Divider
        style={{ width: '100%', margin: 0, borderWidth: '2px', opacity: '80%' }}
        color="darkgray"
        width={1}
        orientation="horizontal"
      />

      <Stack direction="column">
        <NavItemButton
          text="Give us your feedback!"
          textShort="Feedback"
          active={location.pathname === '/feedback'}
          disabled={false}
          icon={<MessageSquare />}
          isOpen={isOpen}
          textSize="medium"
          textColor={theme.colors.purple[600]}
          tabIndex={50}
          onClick={() => window.open('https://nettskjema.no/a/463244')?.focus()}
        />
        <NavItemButton
          text="Learn More"
          textShort="Learn More"
          active={location.pathname === '/learn_more'}
          disabled={false}
          icon={<HelpCircle />}
          isOpen={isOpen}
          textSize="medium"
          textColor="black"
          tabIndex={51}
          onClick={() => navigate('/learn_more')}
        />
        <NavItemButton
          text="Settings"
          textShort="Settings"
          active={location.pathname === '/settings'}
          disabled={false}
          icon={<Settings />}
          isOpen={isOpen}
          textSize="medium"
          textColor="black"
          tabIndex={52}
          onClick={() =>
            window
              .open(
                `https://auth.meshr.org/realms/${process.env.REACT_APP_AUTH_REALM}/account/`,
                '_blank',
              )
              ?.focus()
          }
        />
        <NavItemButton
          text="Log out"
          textShort="Log out"
          active={location.pathname === '/log_out'}
          disabled={false}
          icon={<LogOut />}
          isOpen={isOpen}
          textSize="medium"
          textColor="black"
          tabIndex={53}
          onClick={() => auth.signOutRedirect()}
        />
      </Stack>
    </Stack>
  );

}
