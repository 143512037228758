import { useContext, useEffect, useState } from 'react';

import '@react-sigma/core/lib/react-sigma.min.css';
import { useRegisterEvents, useSigma } from '@react-sigma/core';
import { useAppDispatch, useAppSelector } from '../../lib/hooks';
import { addFloatingDoc } from '../../lib/state/slices/ViewSlice';
import { GraphViewContext } from './Index';

const GraphEvents = (props: {
  onHoverNode: Function;
  isActive?: boolean;
  setNodeContextMenu: any;
}) => {
  const registerEvents = useRegisterEvents();
  const sigma = useSigma();
  const dispatch = useAppDispatch();
  const graph = sigma.getGraph();
  const graphView = useContext(GraphViewContext);

  const countryFilter = useAppSelector((state) => graphView.filter.countrys);
  const importanceFilter = useAppSelector((state) => graphView.filter.importance);
  const caseDetailFilter = useAppSelector((state) => graphView.filter.caseDetail);
  const decisionLevelFilter = useAppSelector((state) => graphView.filter.decisionLevel);
  const timeFilter = useAppSelector((state) => graphView.filter.timeFilter);
  const articleFilter = useAppSelector((state) => graphView.filter.articles);
  const [clickedNodes, setClickedNodes] = useState<Set<string>>(new Set());
  // const degreeFilter = useAppSelector((state) => state.viewReducer.graphViews[props])

  // Filter implementation
  useEffect(() => {
    let filteredNodes: string[] = graph.nodes();
    filteredNodes = filteredNodes.filter((node) =>
      importanceFilter.includes(graph.getNodeAttribute(node, 'importance')),
    );
    filteredNodes = filteredNodes.filter((node) => {
      const nonViolation = graph.getNodeAttribute(node, 'nonviolation');
      const violation = graph.getNodeAttribute(node, 'violation');

      const hasNonViolation = typeof nonViolation === 'string' && nonViolation.length > 0;
      const hasViolation = typeof violation === 'string' && violation.length > 0;

      const filterNonViolation =
        hasNonViolation && !hasViolation ? caseDetailFilter.includes('NONVIOLATION') : true;
      const filterViolation =
        hasViolation && !hasNonViolation ? caseDetailFilter.includes('VIOLATION') : true;
      const filterBoth = hasViolation && hasNonViolation ? caseDetailFilter.includes('BOTH') : true;
      const filterMisc =
        !hasNonViolation && !hasViolation ? caseDetailFilter.includes('MISCELLANEOUS') : true;

      return filterNonViolation && filterViolation && filterMisc && filterBoth;
    });
    filteredNodes = filteredNodes.filter((node) =>
      decisionLevelFilter.includes(graph.getNodeAttribute(node, 'chambertype')),
    );
    filteredNodes = filteredNodes.filter((node) => {
      const countries = graph.getNodeAttribute(node, 'country');
      for (let country of countries) {
        if (countryFilter.includes(country)) return true;
      }
      return false;
    });
    filteredNodes = filteredNodes.filter((node) => {
      let nodeDate = new Date(graph.getNodeAttribute(node, 'judgementdate'));
      return nodeDate.getTime() >= timeFilter.lower && nodeDate.getTime() <= timeFilter.upper;
    });

    filteredNodes = filteredNodes.filter((node) => {
      const violation = graph.getNodeAttribute(node, 'violation')?.split(';');
      console.log(violation)
      const nonviolation = graph.getNodeAttribute(node, 'nonviolation')?.split(';');
      if (violation) {
        for (let article of violation) {
          for (let filter of articleFilter) {
            let regex = new RegExp(`^${filter}(\\D.*|$)`);
            if (regex.test(article)) return true;
          }
        }
      }

      if (nonviolation) {
        for (let article of nonviolation) {
          for (let filter of articleFilter) {
            let regex = new RegExp(`^${filter}(\\D.*|$)`);
            if (regex.test(article)) return true;
          }
        }
      }
      return false;
    });

    graph.forEachNode((node) => {
      if (filteredNodes.includes(node)) {
        graph.setNodeAttribute(node, 'hidden', false);
      } else {
        graph.setNodeAttribute(node, 'hidden', true);
      }
    });
  }, [
    importanceFilter,
    caseDetailFilter,
    decisionLevelFilter,
    articleFilter,
    timeFilter.lower,
    timeFilter.upper,
    countryFilter,
    graph,
    sigma,
  ]);

  useEffect(() => {
    // Register the events
    registerEvents({
      updated: (event) => {
        // This should be empty at all times
      },

      clickNode: (event) => {
        props.onHoverNode(undefined);
        props.setNodeContextMenu(undefined);

        const nodeId = event.node;
        let newClickedNodes = new Set(clickedNodes);

        if (newClickedNodes.has(nodeId)) {
          newClickedNodes.delete(nodeId);
          graph.forEachEdge((edge) => {
            graph.setEdgeAttribute(edge, 'hidden', false);
          });
        } else {
          newClickedNodes.add(nodeId);
          graph.forEachEdge((edge) => {
            if (!graph.extremities(edge).includes(nodeId)) {
              graph.setEdgeAttribute(edge, 'hidden', true);
            } else {
              graph.setEdgeAttribute(edge, 'hidden', false);
            }
          });
        }
        setClickedNodes(newClickedNodes);

        let node_attributes = graph.getNodeAttributes(event.node);
        dispatch(
          addFloatingDoc({
            graphViewId: graphView.id,
            nodeId,
            id: node_attributes.id,
          }),
        );
        props.onHoverNode(undefined);
        props.setNodeContextMenu(undefined);
      },

      enterNode: (event) => {
        props.setNodeContextMenu(undefined);
        props.onHoverNode({
          id: graph.getNodeAttributes(event.node).id,
          nodeId: event.node,
        });
        let neighbours = graph.neighbors(event.node);

        graph.forEachNode((node) => {
          if (!neighbours.includes(node)) {
            graph.setNodeAttribute(node, 'color', '#E2E2E2');
            graph.setNodeAttribute(node, 'borderColor', '#E2E2E2');
          } else {
            graph.setNodeAttribute(node, 'color', graph.getNodeAttribute(node, 'originalColor'));
            graph.setNodeAttribute(
              node,
              'borderColor',
              graph.getNodeAttribute(node, 'originalBorderColor'),
            );
          }
        });
        graph.setNodeAttribute(
          event.node,
          'color',
          graph.getNodeAttribute(event.node, 'originalColor'),
        );
        graph.setNodeAttribute(
          event.node,
          'borderColor',
          graph.getNodeAttribute(event.node, 'originalBorderColor'),
        );
        graph.forEachEdge((edge) => {
          if (!graph.extremities(edge).includes(event.node)) {
            graph.setEdgeAttribute(edge, 'hidden', true);
          } else {
            graph.setEdgeAttribute(edge, 'hidden', false);
          }
        });
      },

      leaveNode: (payload) => {
        props.onHoverNode(undefined);
        let centerNode = graphView.centerNode;

        graph.forEachNode((node) => {
          graph.setNodeAttribute(node, 'color', graph.getNodeAttribute(node, 'originalColor'));
          graph.setNodeAttribute(
            node,
            'borderColor',
            graph.getNodeAttribute(node, 'originalBorderColor'),
          );
        });
        if (clickedNodes.size > 0) {
          graph.forEachEdge((edge) => {
            let isConnectedToClickedNode = Array.from(clickedNodes).some((clickedNodeId) =>
              graph.extremities(edge).includes(clickedNodeId),
            );
            graph.setEdgeAttribute(edge, 'hidden', !isConnectedToClickedNode);
          });
        } else {
          graph.forEachEdge((edge) => {
            [...graph.extremities(edge)]
              .map((node) => graph.getNodeAttribute(node, 'id') === centerNode)
              .includes(true)
              ? graph.setEdgeAttribute(edge, 'hidden', false)
              : graph.setEdgeAttribute(edge, 'hidden', true);
          });
        }
      },
      downNode: (e) => {
        props.onHoverNode(undefined);
      },
      mouseup: (e) => {},
      mousedown: (e) => {
        // Disable the autoscale at the first down interaction
        if (!sigma.getCustomBBox()) sigma.setCustomBBox(sigma.getBBox());
      },
      mousemove: (e) => {},
      clickStage: (e) => {
        props.setNodeContextMenu(undefined);
      },

      rightClickNode: (event) => {
        event.event.original.preventDefault();
        props.setNodeContextMenu({
          id: graph.getNodeAttributes(event.node).id,
          nodeId: event.node,
        });
      },
    });
  }, [registerEvents, sigma, dispatch, graph, props]);

  return null;
};

export default GraphEvents;
