import { Node } from './api/APITypes';
import { Filter } from './state/ViewTypes';

export default function filterNodes(nodes: Node[], filter: Filter) {
  nodes = nodes.filter((node) => filter.importance.includes(node.properties.importance));
  nodes = nodes.filter((node) => {
    const nonViolation = node.properties.nonviolation;
    const violation = node.properties.violation;
    const hasViolation = violation !== null && violation !== undefined;
    const hasNonViolation = nonViolation !== null && nonViolation !== undefined;

    const filterNonViolation =
      hasNonViolation && !hasViolation ? filter.caseDetail.includes('NONVIOLATION') : true;
    const filterViolation =
      hasViolation && !hasNonViolation ? filter.caseDetail.includes('VIOLATION') : true;
    const filterBoth = hasViolation && hasNonViolation ? filter.caseDetail.includes('BOTH') : true;
    const filterMisc =
      !hasViolation && !hasNonViolation ? filter.caseDetail.includes('MISCELLANEOUS') : true;
    return filterNonViolation && filterViolation && filterBoth && filterMisc;
  });
  nodes = nodes.filter((node) => filter.decisionLevel.includes(node.properties.chambertype));
  nodes = nodes.filter((node) => {
    const countries = node.properties.country;
    for (let country of countries) {
      if (filter.countrys.includes(country)) {
        return true;
      }
    }
  });
  nodes = nodes.filter((node) => {
    const nodeDate = new Date(node.properties.judgementdate ?? 0);
    return (
      nodeDate.getTime() >= filter.timeFilter.lower && nodeDate.getTime() <= filter.timeFilter.upper
    );
  });
  nodes = nodes.filter((node) => {
    const violation: string[] = node.properties.violation?.split(';');
    const nonViolation: string[] = node.properties.nonviolation?.split(';');
    if (violation) {
      for (let article of violation) {
        for (let filterArticle of filter.articles) {
          let regex = new RegExp(`^${filterArticle}(\\D.*|$)`);
          if (regex.test(article)) return true;
        }
      }
    }
    if (nonViolation) {
      for (let article of nonViolation) {
        for (let filterArticle of filter.articles) {
          let regex = new RegExp(`^${filterArticle}(\\D.*|$)`);
          if (regex.test(article)) return true;
        }
      }
    }
    return false
  });
  return nodes;
}
