import { useEffect, useState } from 'react';
import {
  addEdgeToGraph,
  addNodeToGraph,
  graphFromData,
  setNodeSizeBasedOnDegree,
} from '../../lib/GraphFactory';
import { EdgeArrowProgram } from 'sigma/rendering';
import { NodeBorderProgram } from '@sigma/node-border';
import {
  ControlsContainer,
  SearchControl,
  SigmaContainer,
  useLoadGraph,
  useSigma,
} from '@react-sigma/core';
import '@react-sigma/core/lib/react-sigma.min.css';
import GraphEvents from './Events';
import { useAppDispatch, useAppSelector } from '../../lib/hooks';
import NodeContextMenu from './controls/NodeContextMenu';
import { useGetNeighboursQuery, useGetRecommendedNeighboursQuery, useSaveUserStateMutation } from '../../lib/api/GraphAPI';
import { store } from '../../lib/state/Store';
import Filter from './controls/Filter';
import SearchControlWrapper from './SearchControlWrapper';
import LoadingScreen from '../LoadingScreen';
import { Button, List, Text } from '@chakra-ui/react';
import { removeFloatingDoc, setToolOpenStatus } from '../../lib/state/slices/ViewSlice';
import FloatingDocument from './FloatingDocument';
import DocumentCard from '../../components/DocumentCard';
import TimeFilter from './controls/TimeFilter';
import { createContext } from 'react';
import { GraphView } from '../../lib/state/ViewTypes';
import QuickFilter from './controls/QuickFilter';
import ListView from './ListView';

const Header = (props: { graphName: string; isListView: boolean; toggleView: () => void }) => {
  const toolOpenStatus = useAppSelector((state) => state.viewReducer.toolOpenStatus);
  return (
    <div
      style={{
        width: '100%',
        padding: '20px',
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <div>
        <Text fontWeight={800} fontSize={'x-large'}>
          {props.graphName}
        </Text>
      </div>
      <div>
        <Button marginLeft="12px" variant="outline" colorScheme="purple" onClick={props.toggleView}>
          {/* todo: fix the placement of buttons, rn: hardcoded not good */}
          {props.isListView ? 'View as graph' : 'View as list'}
        </Button>
        <Button
          marginLeft={'10px'}
          type="button"
          variant="outline"
          colorScheme="purple"
          onClick={() =>
            store.dispatch(
              setToolOpenStatus({
                ...toolOpenStatus,
                advancedFilters: !toolOpenStatus.advancedFilters,
              }),
            )
          }
        >
          Filter
        </Button>
      </div>
    </div>
  );
};

const LoadNeighbours = (props: { id: string }) => {
  const { data, error, isLoading, isSuccess } = useGetNeighboursQuery({
    id: props.id,
    level: 1,
  });

  const sigma = useSigma();
  const graph = sigma.getGraph();

  useEffect(() => {
    if (data && isSuccess) {
      for (let i = 0; i < data.nodes.length; i++) {
        if (!graph.hasNode(data.nodes[i].elementId)) {
          let node = data.nodes[i];
          addNodeToGraph(node, graph);
        }
      }
      for (let i = 0; i < data.edges.length; i++) {
        if (!graph.hasEdge(data.edges[i].elementId)) {
          let edge = data.edges[i];
          addEdgeToGraph(edge, graph, '');
        }
      }
    }
    setNodeSizeBasedOnDegree(graph, true);
  }, [props.id, data]);

  return null;
};

const RecommendNeighbours = (props: { id: string; nodeId: string }) => {
  const { data, error, isLoading, isSuccess } = useGetRecommendedNeighboursQuery(props.id);

  const sigma = useSigma();
  const graph = sigma.getGraph();
  useEffect(() => {
    if (data && isSuccess) {
      for (let i = 0; i < data.nodes.length; i++) {
        if (!graph.hasNode(data.nodes[i].elementId)) {
          let node = data.nodes[i];
          addNodeToGraph(node, graph);
          graph.addUndirectedEdge(props.nodeId, node.elementId, {
            distance: data.distances[i],
            color: 'orange',
            size: 2 * (1 - data.distances[i]),
          });
        }
      }
    }
    setNodeSizeBasedOnDegree(graph, true);
  }, [props.id, data]);

  return null;
};

const LoadGraph = (props: { data: any; centerNode: string }) => {
  const loadGraph = useLoadGraph();
  const refreshCounter = useAppSelector((state) => state.viewReducer.refreshCounter);
  const sigma = useSigma();

  useEffect(() => {
    loadGraph(
      graphFromData({
        data: props.data,
        centerNode: props.centerNode,
      }),
    );
  }, []);
  useEffect(() => {
    setTimeout(() => {
      sigma.refresh();
      sigma.resize();
    }, 300);
    sigma.refresh();
    sigma.resize();
  }, [refreshCounter]);

  return null;
};

const GraphViewContext = createContext<GraphView>({
  centerNode: '',
  id: '',
  name: '',
  floatingDocuments: [],
  filter: {
    decisionLevel: [],
    importance: [],
    caseDetail: [],
    articles: [],
    timeFilter: { lower: 0, upper: 0 },
    countrys: [],
  },
});

export const GraphLayout = (props: { isActive?: boolean; graphViewId: string }) => {
  const dispatch = useAppDispatch();
  const graphView: GraphView | undefined = useAppSelector(
    (state) => state.viewReducer.graphViews[props.graphViewId],
  );
  if (!graphView) {
    console.error(`GraphView with id ${props.graphViewId} not found`);
  }

  const { data, error, isLoading, isSuccess } = useGetNeighboursQuery({
    id: graphView?.centerNode,
    level: 1,
  });

  const [isListView, setIsListView] = useState(false);
  const toggleView = () => setIsListView((prev) => !prev);

  const [loadNeighbours, setLoadNeighbours] = useState<string>();
  const [recommendNeighbours, setRecommendNeighbours] = useState<{ id: string; nodeId: string }>();
  const [hoveredNode, onHover] = useState<{
    nodeId: string;
    id: string;
  }>();
  const [nodeContextMenu, setNodeContextMenu] = useState<{
    nodeId: string;
    id: string;
  }>();

  let floatingDocuments = useAppSelector(
    (state) => state.viewReducer.graphViews[props.graphViewId].floatingDocuments,
  );
  const openTools = useAppSelector((state) => state.viewReducer.toolOpenStatus);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (data && isSuccess) {
    return (
      <div style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}>
        <GraphViewContext.Provider value={graphView}>
          <Header
            graphName={store.getState().viewReducer.graphViews[props.graphViewId].name}
            toggleView={toggleView}
            isListView={isListView}
          />
          <style>
            {`
            .sigma-container {
              background-color: #E2E8F0;
              flex-grow: 1;
            }
          `}
          </style>
          {isListView ? (
           <ListView data={data} key={`listView${props.graphViewId}`}/> 
          ) : (
            <SigmaContainer
              style={{ height: '100%', width: '100%', display: 'flex' }}
              settings={{
                //hoverRenderer: () => {},
                defaultEdgeType: 'arrow',
                edgeProgramClasses: { arrow: EdgeArrowProgram },
                nodeProgramClasses: {
                  border: NodeBorderProgram,
                },
              }}
            >
              <LoadGraph
                data={data}
                centerNode={store.getState().viewReducer.graphViews[props.graphViewId].centerNode}
              />
              <GraphEvents
                onHoverNode={onHover}
                isActive={props.isActive}
                setNodeContextMenu={setNodeContextMenu}
              />
              <ControlsContainer position="top-right">
                <SearchControlWrapper>
                  <SearchControl />
                </SearchControlWrapper>
              </ControlsContainer>
              {floatingDocuments.map((doc) => (
                <FloatingDocument
                  elementId={doc.nodeId}
                  id={doc.id}
                  onClose={() => {
                    dispatch(
                      removeFloatingDoc({
                        graphViewId: graphView.id,
                        floatingDocId: doc.id,
                      }),
                    );
                  }}
                />
              ))}
              <TimeFilter style={{ position: 'absolute', bottom: '20px', left: '2%' }} />
              <Filter
                style={{ position: 'absolute', top: '20px', right: '20px', width: '300px' }}
              />
              <QuickFilter style={{ position: 'absolute', top: '20px', left: '20px' }} />
              {nodeContextMenu ? (
                <NodeContextMenu
                  id={nodeContextMenu.id}
                  nodeId={nodeContextMenu.nodeId}
                  loadNeighbours={(id: string) => {
                    setLoadNeighbours(id);
                  }}
                  recommendNeighbours={(id: string, nodeId: string) => {
                    setRecommendNeighbours({ id, nodeId });
                  }}
                />
              ) : null}

              {loadNeighbours ? <LoadNeighbours id={loadNeighbours} /> : null}
              {recommendNeighbours ? (
                <RecommendNeighbours
                  id={recommendNeighbours.id}
                  nodeId={recommendNeighbours.nodeId}
                />
              ) : null}
            </SigmaContainer>
          )}
        </GraphViewContext.Provider>
      </div>
    );
  } else {
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        Error loading the Graph
      </div>
    );
  }
};

export { GraphViewContext };
