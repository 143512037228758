import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Graph, Node, Relationship, File } from './APITypes';
import View from '../state/ViewTypes';

// API endpoint for fetching data
export const graphApi = createApi({
  reducerPath: 'graphApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, api) => {
      let ai: any = api.getState();
      headers.set('Authorization', 'Bearer ' + ai.authReducer.accesstoken);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    // <Type of data the call will return, Type of parameter being passed to the query function>
    getGraph: builder.query<Graph, void>({
      query: () => 'graph',
    }),
    getNodes: builder.query<Array<Node>, void>({
      query: () => 'nodes',
    }),
    getNode: builder.query<Node, string>({
      query: (id) => `node?id=${id}`,
    }),
    getEdges: builder.query<Array<Relationship>, void>({
      query: () => 'edges',
    }),
    getNeighbours: builder.query<Graph, { id: string; level: number }>({
      query: ({ id, level }) => `neighbours?id=${id}&level=${level}`,
    }),
    getRecommendedNeighbours: builder.query<
      { nodes: Array<Node>; distances: Array<number> },
      string
    >({
      query: (id) => `ai/neighbours?id=${id}`,
    }),
    getFile: builder.query<File, string>({
      query: (id) => `file?id=${id}`,
    }),
    search: builder.query<Array<Node>, string>({
      query: (q) => `search?q=${q}`,
    }),
    AIsearch: builder.query<{ nodes: Array<Node>; distances: Array<number> }, string>({
      query: (q) => `ai/search?q=${q}`,
    }),
    getUserState: builder.query<Array<{id: string, json_state: View}>, void>({
      query: () => 'user/state',
    }),
    saveUserState: builder.mutation<void, View>({
      query: (body) => ({
        url: `user/state`,
        method: 'POST',
        body
      }),
    }),
  }),
});

export const {
  useGetGraphQuery,
  useGetEdgesQuery,
  useGetNodesQuery,
  useGetNodeQuery,
  useGetFileQuery,
  useGetNeighboursQuery,
  useSearchQuery,
  useAIsearchQuery,
  useLazyGetNodeQuery,
  useGetRecommendedNeighboursQuery,
  useSaveUserStateMutation,
  useGetUserStateQuery,
  useLazyGetUserStateQuery
} = graphApi;
