import React, { useEffect } from 'react';
import { useAppSelector } from './hooks';
import { useSaveUserStateMutation } from './api/GraphAPI';

export default function AutoSaver() {
  const state = useAppSelector((state) => state.viewReducer);
  const [saveView] = useSaveUserStateMutation();

  const timeoutRef = React.useRef<number | null>(null);

  useEffect(() => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }
    const newTimeout = window.setTimeout(() => {
      saveView(state);
      console.log("AutoSaver", "Saving");
    }, 5000);
    timeoutRef.current = newTimeout;
  }, [state, saveView]);

  return null
}
