import { Flex, List, Text } from "@chakra-ui/react";
import { Graph } from "../../lib/api/APITypes";
import DocumentCard from "../../components/DocumentCard";
import { useContext, useState } from "react";
import { GraphViewContext } from "./Index";
import filterNodes from "../../lib/Filter";
import { useAppSelector } from "../../lib/hooks";
import SortDropdown from "../../components/SortDropdown";
import sortNodes from "../../lib/Sort";

export default function ListView(props: { data: Graph }) {
    const graphView = useContext(GraphViewContext);
    const filter = useAppSelector(state => state.viewReducer.graphViews[graphView.id].filter);
    const [sortOrder, setSortOrder] = useState<'default' | 'date' | 'relevance'>('default');
    return <div
        style={{
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            overflowY: 'auto',
        }}
    >
        <Flex direction={'row'} justifyContent={'space-between'}>
            <Text fontWeight={800} fontSize="x-large" paddingBottom="20px">
                List of all cases in this graph
            </Text>
            <SortDropdown setSortOrder={setSortOrder} sortOrder={sortOrder}/>
        </Flex>
        <List
            spacing={'8px'}
            width={'100%'}
            alignItems={'center'}
            display={'flex'}
            flexDirection={'column'}
        >
            {sortNodes(filterNodes(props.data.nodes, filter), sortOrder).map((node) => (
                <DocumentCard key={node.properties.id} id={node.properties.id}></DocumentCard>
            ))}
        </List>
    </div>
}