import { useAuth } from 'oidc-react';
import { useLazyGetUserStateQuery } from './api/GraphAPI';
import { setView } from './state/slices/ViewSlice';
import { store } from './state/Store';
import { useEffect } from 'react';
export default function LoadAppStateFromServer() {
  const [trigger, result, lastPromiseInfo] = useLazyGetUserStateQuery();

  const auth = useAuth();

  useEffect(() => {
    trigger();
    if (result.isSuccess && result.data && result.data.length > 0) {
      store.dispatch(setView(result.data[0].json_state));
    }
  }, [auth.userData?.profile.sub, result.isSuccess, result.data]);
  return null;
}
